import { css } from '@emotion/react';
import { EmailOutlined } from '@mui/icons-material';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { BodyText } from '@headway/helix/BodyText';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { MobilePhone as MobilePhoneIcon } from '@headway/icons/dist/provider';

interface PendingProfileProps {
  provider: ProviderRead;
}

export const PendingProfileBookingMessage = ({
  provider,
}: PendingProfileProps) => {
  const pendingProfileHeader = `${provider.displayFirstName}'s profile isn't ready for booking yet.`;
  const pendingProfileMessage = `Since ${provider.displayFirstName} is new to Headway, we're still in the process of activating their profile. In the meantime, you can reach out to them directly about scheduling a consultation.`;

  return (
    <>
      <div>
        <SectionHeader>{pendingProfileHeader}</SectionHeader>
      </div>
      <div>
        <BodyText color="gray">{pendingProfileMessage}</BodyText>
      </div>
      <div css={contactInfoContainerCss}>
        {!!provider.phone && (
          <>
            <div css={iconContainerCss}>
              <MobilePhoneIcon
                width={theme.spacing.x7}
                height={theme.spacing.x7}
              />
              <span css={contactInfoTextCss}>{provider.phone}</span>
            </div>
          </>
        )}
        {!!provider.email && (
          <>
            <div css={iconContainerCss}>
              <EmailOutlined
                width={theme.spacing.x7}
                height={theme.spacing.x7}
              />
              <span css={contactInfoTextCss}>{provider.email} </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

/* Styles */
export const pendingProfileContainerCss = css`
  border: 1px solid ${theme.color.system.borderGray};
  border-radius: 8px;
  // prettier-ignore
  padding: ${theme.spacing.x10} ${theme.spacing.x10} ${theme.spacing
    .x10} ${theme.spacing.x10};
  background-color: ${theme.color.system.backgroundGray};
  align-self: flex-start;
`;

const iconContainerCss = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: ${theme.spacing.x3};
  justify-content: flex-start;
  & path {
    fill: ${theme.color.system.black};
  }
`;

const contactInfoContainerCss = css`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.x5};
  row-gap: ${theme.spacing.x2};
  width: 100%;
  ${theme.media.desktop} {
    width: 100%;
  }
`;

export const contactInfoTextCss = css`
  color: ${theme.color.system.black};
  font-family: ${theme.typography.subbody.regular.fontFamily};
  font-size: ${theme.typography.subbody.regular.fontSize};
  font-weight: bold;
  line-height: ${theme.typography.subbody.regular.lineHeight};
`;
