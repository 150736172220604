import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ContentText } from '@headway/helix/ContentText';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { hasQualifiedForNewProfile } from '@headway/shared/utils/providers';
import { logWarning } from '@headway/shared/utils/sentry';
import { theme } from '@headway/ui/theme';

import { ExpandableText } from './ExpandableText';
import { bodyTextCss, StyledSectionHeader } from './Text';

const BioSection: React.FC<{ header: string; text: string }> = ({
  header,
  text,
}) => (
  <div
    css={{
      wordBreak: 'break-word',
    }}
  >
    <StyledSectionHeader>{header}</StyledSectionHeader>
    <ExpandableText
      maxLines={6}
      buttonLabel="Show more"
      buttonCss={{ paddingTop: theme.space.xs }}
    >
      <div
        css={bodyTextCss}
        dangerouslySetInnerHTML={{
          __html: sanitize(
            text
              .replace(/^(<p><br><\/p>)*/gm, '')
              .replace(/(<p><br><\/p>)*$/gm, '')
          ),
        }}
      />
    </ExpandableText>
  </div>
);

type Props = {
  className?: string;
  provider: ProviderRead;
  sectionId: string;
};

const IntroSection: React.FC<Props> = ({ className, provider, sectionId }) => {
  const hasNewBioSections = hasQualifiedForNewProfile({
    bioAboutYou: provider.bioAboutYou,
    bioTherapyApproach: provider.bioTherapyApproach,
    bioTakeAways: provider.bioTakeAways,
  });

  if (!hasNewBioSections && !provider.statementHtml) {
    logWarning(`No bio information for provider`, {
      extra: { providerId: provider.id },
    });
  }
  const noStatementMessage = `${provider.displayFirstName} is still completing their profile.`;

  return (
    <section id={sectionId} className={className}>
      {hasNewBioSections ? (
        <>
          {provider.bioAboutYou ? (
            <BioSection
              header="Great to meet you!"
              text={provider.bioAboutYou}
            />
          ) : null}
          {provider.bioTherapyApproach ? (
            <BioSection
              header="A bit about my approach to therapy"
              text={provider.bioTherapyApproach}
            />
          ) : null}
          {provider.bioTakeAways ? (
            <BioSection
              header="What you can expect from our first session"
              text={provider.bioTakeAways}
            />
          ) : null}
        </>
      ) : provider.statementHtml ? (
        <BioSection header="About Me" text={provider.statementHtml} />
      ) : (
        <div className="mt-4 break-words">
          <ContentText>{noStatementMessage}</ContentText>
        </div>
      )}
    </section>
  );
};

export { IntroSection };
