import { css } from '@emotion/react';
import React from 'react';

import { ProviderHighlight } from '@headway/api/models/ProviderHighlight';
import { ProviderHighlightKey } from '@headway/api/models/ProviderHighlightKey';
import { HighlightCalendar } from '@headway/icons/dist/provider/HighlightCalendar';
import { HighlightDiamond } from '@headway/icons/dist/provider/HighlightDiamond';
import { HighlightHeadwayRelationship } from '@headway/icons/dist/provider/HighlightHeadwayRelationship';
import { HighlightPeople } from '@headway/icons/dist/provider/HighlightPeople';
import { HighlightPhone } from '@headway/icons/dist/provider/HighlightPhone';
import { HighlightPrescriber } from '@headway/icons/dist/provider/HighlightPrescriber';
import { HighlightTherapyChair } from '@headway/icons/dist/provider/HighlightTherapyChair';
import { checkExhaustive } from '@headway/shared/utils/types';
import { theme } from '@headway/ui/theme';

interface ProviderHighlightCalloutProps {
  highlight: ProviderHighlight;
  reverseIconPosition?: boolean;
}
export const ProviderHighlightCallout = ({
  highlight,
  reverseIconPosition = false,
}: ProviderHighlightCalloutProps) => {
  const highlightCss = generateHighlightsCss(reverseIconPosition);
  switch (highlight.key) {
    case ProviderHighlightKey.AVAILABLE_THIS_WEEK:
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightCalendar />
          </div>
          <div>
            <div className="title">Available this week</div>
          </div>
        </div>
      );
    case ProviderHighlightKey.NUM_AVAILABILITIES_THIS_WEEK:
      const numOpenings = parseInt(highlight.quantifier ?? '0');
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightCalendar />
          </div>
          <div>
            <div className="title">
              {numOpenings} opening{numOpenings === 1 ? '' : 's'} in the next 7
              days
            </div>
          </div>
        </div>
      );
    case ProviderHighlightKey.RARE_FIND:
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightDiamond />
          </div>
          <div>
            <div className="title">Limited openings left</div>
            <div className="subtext">This provider is usually fully booked</div>
          </div>
        </div>
      );
    case ProviderHighlightKey.IN_PERSON:
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightTherapyChair />
          </div>
          <div>
            <div className="title">Offers in-person</div>
            <div className="subtext">
              This provider has openings for in-person sessions
            </div>
          </div>
        </div>
      );
    case ProviderHighlightKey.PHONE_CONSULTS:
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightPhone />
          </div>
          <div>
            <div className="title">Intro phone consultations</div>
            <div className="subtext">
              Offers a free 15 min phone consultation to get started
            </div>
          </div>
        </div>
      );
    case ProviderHighlightKey.PRESCRIBER:
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightPrescriber />
          </div>
          <div>
            <div className="title">Offers medication management</div>
            <div className="subtext">
              Licensed to prescribe medication in treatment
            </div>
          </div>
        </div>
      );
    case ProviderHighlightKey.EXPERIENCED:
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightHeadwayRelationship />
          </div>
          <div>
            <div className="title">Experienced with Headway</div>
            <div className="subtext">Has seen many clients on Headway</div>
          </div>
        </div>
      );
    case ProviderHighlightKey.LOYAL_CLIENTS:
      return (
        <div css={highlightCss}>
          <div className="icon">
            <HighlightPeople />
          </div>
          <div>
            <div className="title">Loyal clients</div>
            <div className="subtext">
              Clients often come back to see this provider again
            </div>
          </div>
        </div>
      );
    default:
      checkExhaustive(highlight.key);
  }
};

const generateHighlightsCss = (reverseIconPosition: boolean) => css`
  display: flex;
  gap: ${theme.space.xs};
  align-items: center;
  ${reverseIconPosition &&
  `
  flex-direction: row-reverse;
  justify-content: space-between;
  border: 1px solid ${theme.color.lightGray};
  border-radius: 4px;
  padding: ${theme.space.base};
  `}

  .icon {
    height: 48px;
    width: 48px;
  }
  & .title {
    font-size: ${theme.fontSize.base};
    color: ${theme.color.black};
    ${theme.media.medium}: {
      font-size: ${theme.fontSize.xl};
    }
  }
  & .subtext {
    font-size: ${theme.fontSize.sm};
    color: ${theme.color.textGray};
    ${reverseIconPosition &&
    `
    margin-top: ${theme.space.xs2};
    font-size: ${theme.fontSize.xs};
  `}
  }
`;
