import { css } from '@emotion/react';
import LockIcon from '@mui/icons-material/LockOutlined';
import { Alert, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AxiosRequestConfig } from 'axios';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment-timezone';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { NextSeo } from 'next-seo';
import React, { useEffect, useMemo } from 'react';
import { ProfilePage, WithContext } from 'schema-dts';

import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { ModalityRead } from '@headway/api/models/ModalityRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderCredentialRead } from '@headway/api/models/ProviderCredentialRead';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderHighlightKey } from '@headway/api/models/ProviderHighlightKey';
import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSpecialtyBookingPreference } from '@headway/api/models/ProviderSpecialtyBookingPreference';
import { ProviderStyleTagRead } from '@headway/api/models/ProviderStyleTagRead';
import { SpecialtyNested } from '@headway/api/models/SpecialtyNested';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { getFlag } from '@headway/feature-flags/node';
import { useFlag } from '@headway/feature-flags/react';
import { Banner } from '@headway/helix/Banner';
import { toasts } from '@headway/helix/Toast';
import {
  AcceptsInsurance as AcceptsInsuranceIcon,
  InPerson as InPersonIcon,
  Specialties as SpecialtiesIcon,
  VideoChat as VideoChatIcon,
} from '@headway/icons/dist/provider';
import { statesToDisplayNames } from '@headway/shared/constants/unitedStatesDisplayNames';
import { MULTI_STATE_CREDENTIALING_AGORA } from '@headway/shared/FeatureFlags/flagNames';
import {
  dehydrate,
  makeQueryClient,
  useQuery,
} from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import {
  carriersWithSubsidiaries,
  convertCarrierIdToPatientSearchableCarrierId,
  convertProviderCarriersToSearchableCarriers,
} from '@headway/shared/utils/carriers';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';
import { isAppointmentReady } from '@headway/shared/utils/providerFrontEndCarrier';
import { isProviderLiveInState } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import {
  formatProviderPronouns,
  getProviderDisplayFirstAndLastWithPrenomial,
  isProviderTelehealthOnly,
  providerSupportsTelehealth,
} from '@headway/shared/utils/providers';
import { logException, logWarning } from '@headway/shared/utils/sentry';
import { getContentOfHtml } from '@headway/shared/utils/strings';
import { Chip } from '@headway/ui/Chip';
import { MaxWidthSection, VectorBackground } from '@headway/ui/landing';
import { ProviderProfilePhoto } from '@headway/ui/providers/ProviderProfilePhoto';
import { theme } from '@headway/ui/theme';

import { PageWrapper } from '../../../components/Nav/PageWrapper';
import { BookingBanner } from '../../../components/Providers/ProfilePage/BookingBanner';
import {
  CostAndCarriersSection,
  FrontEndCarrierNestedWithName,
} from '../../../components/Providers/ProfilePage/CostAndCarriersSection';
import { ExtendableTooltipList } from '../../../components/Providers/ProfilePage/ExtendableTooltipList';
import { FactsSection } from '../../../components/Providers/ProfilePage/FactsSection';
import { IntroSection } from '../../../components/Providers/ProfilePage/IntroSection';
import { ListHighlightable } from '../../../components/Providers/ProfilePage/ListHighlightable';
import {
  PendingProfileBookingMessage,
  pendingProfileContainerCss,
} from '../../../components/Providers/ProfilePage/PendingProfile';
import { ProviderPriceAndAvailability } from '../../../components/Providers/ProfilePage/ProviderPriceAndAvailability';
import { StickyTabBar } from '../../../components/Providers/ProfilePage/StickyTabBar';
import { StyleSection } from '../../../components/Providers/ProfilePage/StyleSection';
import { ProviderHighlightCallout } from '../../../components/Providers/ProviderHighlight';
import { useRouter } from '../../../hooks/useRouter';
import { buildLaunchDarklyContextFromRequest } from '../../../lib/featureFlags/context/builder';
import { IAuthStore, IUiStore, withStores } from '../../../stores/withStores';
import {
  getCarriersAndProviderPriceCacheKey,
  PROVIDER_BOOKING_PREFERENCE_CACHE_KEY,
  PROVIDER_HIGHLIGHTS_CACHE_KEY,
} from '../../../utils/cacheKeys';
import {
  getAuthCookie,
  GetServerSidePropsContextWithUser,
} from '../../../utils/cookie';
import { getHeaderHeight } from '../../../utils/css';
import { HEADER_HEIGHT_VARIABLE } from '../../../utils/cssVariables';
import { getFullURLForPath } from '../../../utils/request';

export type ProviderProfilePageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
> & {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
};

const ProviderProfilePageSeo: React.FC<{
  provider: ProviderRead;
  visibleFrontEndCarriers: FrontEndCarrierNestedWithName[];
}> = ({ provider, visibleFrontEndCarriers }) => {
  const {
    bioAboutYou,
    bioTakeAways,
    degreeType,
    degreeType2,
    highlights,
    postnomials,
    school,
    school2,
  } = provider;

  const displayNameWithPrenomials =
    getProviderDisplayFirstAndLastWithPrenomial(provider);

  const uniquePostnomials = uniqBy(postnomials, (str) => str.toLowerCase());
  const displayNameWithPostNomials = [
    displayNameWithPrenomials,
    uniquePostnomials,
  ]
    .filter(Boolean)
    .join(' - ');

  const description = useMemo(() => {
    const defaultDescription =
      `${displayNameWithPrenomials}, ${postnomials?.join(', ')}. ` +
      (degreeType ? `${degreeType}${school ? ', ' : '. '} ` : '') +
      (school ? `${school}. ` : '') +
      (degreeType2 ? `${degreeType2}${school2 ? ', ' : '. '} ` : '') +
      (school2 ? `${school2}. ` : '') +
      (visibleFrontEndCarriers && visibleFrontEndCarriers.length > 0
        ? `Accepts ${visibleFrontEndCarriers
            .map((carrier) => carrier.name)
            .join(', ')}.`
        : '');

    // getContentOfHtml uses DOMParser under the hood, which may not be available at the first render
    if (typeof DOMParser === 'undefined') {
      return defaultDescription;
    }

    if (bioAboutYou) {
      return getContentOfHtml(bioAboutYou);
    }
    if (bioTakeAways) {
      return getContentOfHtml(bioTakeAways);
    }
    if (highlights?.[0]) {
      return highlights?.[0];
    }

    return defaultDescription;
  }, [provider, visibleFrontEndCarriers]);

  const profileStructuredData: WithContext<ProfilePage> = {
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    dateCreated: provider.createdOn,
    dateModified: provider.updatedOn,
    mainEntity: {
      '@type': 'Person',
      identifier: provider.id.toString(),
      name: displayNameWithPostNomials,
      description,
    },
  };

  return (
    <React.Fragment>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: sanitize(JSON.stringify(profileStructuredData)),
        }}
      />
      <NextSeo
        title={displayNameWithPostNomials}
        description={description}
        openGraph={{}} // empty object currently needed due to https://github.com/garmeeh/next-seo/issues/544
        canonical={getFullURLForPath(`/providers/${provider.slug}`)}
      />
    </React.Fragment>
  );
};

const TOAST_MESSAGES: Record<string, string> = {
  COST_UPDATE_REMINDER:
    'We’ll send you an email when we confirm an updated cost estimate',
};
const ProviderProfilePage = ({
  addresses,
  ageGroups,
  searchableCarriers,
  AuthStore: { user },
  focusAreas,
  isPreviewing,
  otherTreatmentAreas,
  preferredCarrierId,
  isBlueCard,
  provider,
  providerBookingPreference,
  providerCredentials,
  providerFrontEndCarriers,
  providerStyleTags,
  treatmentApproaches,
  sessionId,
  isMSCAgoraEnabled,
  isProfilePending,
}: ProviderProfilePageProps) => {
  const router = useRouter();
  const isMscAgoraEnabled = useFlag(MULTI_STATE_CREDENTIALING_AGORA);
  const highlightsQuery = useQuery(
    [PROVIDER_HIGHLIGHTS_CACHE_KEY, provider.id, user.id],
    () => {
      const requestedProviderHighlights = [
        ProviderHighlightKey.AVAILABLE_THIS_WEEK,
        ProviderHighlightKey.NUM_AVAILABILITIES_THIS_WEEK,
        ProviderHighlightKey.EXPERIENCED,
        ProviderHighlightKey.RARE_FIND,
        ProviderHighlightKey.LOYAL_CLIENTS,
        ProviderHighlightKey.PHONE_CONSULTS,
        ProviderHighlightKey.PRESCRIBER,
        ProviderHighlightKey.IN_PERSON,
      ];
      return ProviderApi.getProviderHighlights(provider.id, {
        user_id: user.id,
        user_tzinfo: moment.tz.guess(),
        requested_provider_highlights: requestedProviderHighlights,
      });
    }
  );
  const providerHighlights = highlightsQuery.data;

  useEffect(() => {
    trackPageView({
      name: 'Provider',
      properties: {},
    });

    trackEvent({
      name: 'Provider Page Viewed',
      properties: {
        providerId: provider.id,
        isBillingAndBenefitsM1LaunchUser: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      router.query?.toastMessage &&
      TOAST_MESSAGES[router.query.toastMessage as string]
    ) {
      const { toastMessage, ...query } = router.query;

      router.replace({
        pathname: router.pathname,
        query,
      });
      const message = TOAST_MESSAGES[toastMessage as string];
      toasts.add(message, {
        variant: 'positive',
      });
    }
  }, [router]);

  const displayNameWithPrenomials =
    getProviderDisplayFirstAndLastWithPrenomial(provider);

  // if a provider has specialties but none of them are focus areas, promote
  // those to the "primary" specialties (shown in boxes up top) and remove
  // them from "More specialties" row in facts section
  const primarySpecialties =
    focusAreas.length > 0 ? focusAreas : otherTreatmentAreas;
  const secondarySpecialties = focusAreas.length > 0 ? otherTreatmentAreas : [];

  const searchedState = router.query?.state as UnitedStates;
  const searchedProviderLicenseState =
    provider.activeProviderLicenseStates.find(
      (pls) => pls.state === searchedState
    );

  const filteredProviderFrontEndCarriers = providerFrontEndCarriers
    .filter(
      (pfec) =>
        pfec.appointmentReadyDate &&
        new Date(pfec.appointmentReadyDate) <= new Date()
    )
    .filter((pfec) => {
      if (isMscAgoraEnabled) {
        const activeAndLivePLSIds = provider.activeProviderLicenseStates
          .filter((pls) => !!pls.liveOn)
          .map((pls) => pls.id);

        return activeAndLivePLSIds.includes(pfec.providerLicenseStateId);
      } else {
        return pfec.providerLicenseStateId === provider.providerLicenseState.id;
      }
    });

  const visibleFrontEndCarriers = getVisibleCarriersForProvider(
    filteredProviderFrontEndCarriers,
    isMscAgoraEnabled,
    searchableCarriers,
    searchedProviderLicenseState
  );

  const visibleCarriers = filteredProviderFrontEndCarriers.filter((carrier) => {
    const isVisibleCarrier =
      !carrier.hiddenFromProfile && isAppointmentReady(carrier);

    // If provider is live in searched state, only return carriers for that state
    if (
      isMscAgoraEnabled &&
      !!searchedProviderLicenseState &&
      isProviderLiveInState(searchedProviderLicenseState)
    ) {
      return (
        isVisibleCarrier && carrier.providerLicenseState.state === searchedState
      );
    }
    return isVisibleCarrier;
  });

  const visiblePracticeStateNames = [
    ...new Set(
      filteredProviderFrontEndCarriers
        .map((carrier) => carrier.providerLicenseState)
        .filter((pls) => isProviderLiveInState(pls))
        .map((pls) => pls.state)
    ),
  ].sort();

  const filteredProviderCredentials = providerCredentials.filter(
    (providerCredential) =>
      visiblePracticeStateNames.some(
        (state) => state === providerCredential.state
      )
  );

  const carriersByParent = groupBy(
    searchableCarriers,
    (c) => c.eligibilityEffectiveCarrierId
  );

  const providerSearchableCarriers =
    convertProviderCarriersToSearchableCarriers(
      filteredProviderFrontEndCarriers,
      searchableCarriers
    );
  const inNetworkFrontEndCarriers = carriersWithSubsidiaries(
    providerSearchableCarriers,
    carriersByParent
  );

  // check if the carrier the user has on file as their active insurance
  // is INN with any of the provider's carriers
  const onFileInNetworkCarrier = inNetworkFrontEndCarriers.find((carrier) => {
    const userCarrierId = user.activeUserInsurance?.frontEndCarrierId;
    return (
      userCarrierId &&
      convertCarrierIdToPatientSearchableCarrierId(userCarrierId) === carrier.id
    );
  });

  // check if the carrier the user searched by (what they filtered by on the search page
  // and was passed in through the query string) is INN with any of the provider's carriers
  const inNetworkCarrierFromQueryString = inNetworkFrontEndCarriers.find(
    (carrier) => {
      return preferredCarrierId === carrier.id;
    }
  );

  let blueCardCarrier = null;
  // only fetch the carrier from all carriers if its BlueCard. Otherwise its there from fetching
  // the providers credentialed carriers
  if (isBlueCard && isFrontEndCarrierIdBcbs(preferredCarrierId || 0)) {
    blueCardCarrier = searchableCarriers.find(
      (carrier) => carrier.id === preferredCarrierId
    );
  }

  const highlightedCarrier = isBlueCard
    ? blueCardCarrier
    : inNetworkCarrierFromQueryString;
  const highlightedCarrierText = highlightedCarrier?.name
    ? `${highlightedCarrier.name}${
        isBlueCard && blueCardCarrier ? ' (through BlueCard)' : ''
      }`
    : '';

  const highlightCarriersListItems = [
    ...new Set(
      convertProviderCarriersToSearchableCarriers(
        visibleCarriers,
        searchableCarriers
      ).map((c) => c.name)
    ),
  ];

  if (isBlueCard && highlightedCarrierText !== '') {
    highlightCarriersListItems.push(highlightedCarrierText);
  }

  const muiTheme = useTheme();
  const twoColumnLayoutMediaQuery = muiTheme.breakpoints.up('md');
  const twoColumnLayout = useMediaQuery(twoColumnLayoutMediaQuery);

  const tabBarRef = React.useRef<HTMLDivElement>(null);
  const tabbableSections = {
    intro: {
      id: 'intro',
      tabName: 'Intro',
    },
    ...(providerStyleTags.length > 0
      ? {
          style: {
            id: 'style',
            tabName: 'Style',
          },
        }
      : {}),
    ...(!isProfilePending
      ? {
          cost: {
            id: 'cost',
            tabName: 'Cost',
          },
        }
      : {}),
    more: {
      id: 'more',
      tabName: 'More info',
    },
  } as const;

  // we need a custom scroll offset to prevent the top of the section
  // from being cut off by the site nav bar + sticky tab bar.
  // we calculate the offset by summing the heights of the site nav bar
  const getTabBarHeight = (): number => {
    // if the component isn't mounted, we can't perform the calculations for the offset,
    // so we return an appoximate value. once the component is mounted, we'll get the
    // correct value.
    // it seems unlikely/impossible for someone to click a tab before the component is mounted?
    if (
      !tabBarRef.current ||
      typeof window === 'undefined' ||
      typeof document === 'undefined'
    ) {
      return 50;
    }

    return tabBarRef.current.offsetHeight;
  };

  const headerHeight = getHeaderHeight();
  const tabBarHeight = getTabBarHeight();
  const stickyHeadersHeight = headerHeight + tabBarHeight;
  const overviewBoxIconSize = theme.fontSize.xl2;

  return (
    <PageWrapper
      hideZendesk={!twoColumnLayout}
      a11yModalSx={twoColumnLayout ? {} : { bottom: theme.space.xl7 }} // TODO APR use css var
    >
      <ProviderProfilePageSeo
        provider={provider}
        visibleFrontEndCarriers={visibleFrontEndCarriers}
      />
      {isPreviewing && (
        <Alert icon={<LockIcon />} css={alertBannerCss}>
          Only you can view your profile right now. Once you’re{' '}
          <a
            href={`${process.env.NEXT_PUBLIC_SIGMUND_URL}/insurance-status`}
            target="_blank"
            rel="noreferrer"
          >
            live with insurance
          </a>
          , it will be public on Headway.
        </Alert>
      )}
      {isProfilePending && (
        <Banner
          variant="warning"
          children={`This provider profile is still pending`}
        />
      )}
      <VectorBackground css={headerBackgroundCss(twoColumnLayoutMediaQuery)} />
      <MaxWidthSection css={maxWidthSectionCss(twoColumnLayoutMediaQuery)}>
        <div css={profileContainerCss(twoColumnLayoutMediaQuery)}>
          <div css={{ paddingRight: twoColumnLayout ? theme.space.xl5 : 0 }}>
            <div css={headerCss(twoColumnLayoutMediaQuery)}>
              <ProviderProfilePhoto
                photoUrl={transformCloudflareImg({
                  src: provider.photoUrl,
                  width: 500,
                })}
                provider={provider}
                css={headerPhotoCss(twoColumnLayoutMediaQuery)}
              />
              <div className="ml-0">
                <div className="flex items-baseline">
                  <h1
                    className={`m-0 ${
                      twoColumnLayout
                        ? 'hlx-typography-headline-h3'
                        : 'hlx-typography-headline-h4'
                    }`}
                  >
                    {displayNameWithPrenomials}
                  </h1>
                  {twoColumnLayout ? (
                    provider.pronouns && (
                      <span css={pronounCss(twoColumnLayoutMediaQuery)}>
                        {formatProviderPronouns(provider)}
                      </span>
                    )
                  ) : (
                    <>
                      <span css={headerChipsContainerMobileCss}>
                        {!isProviderTelehealthOnly(addresses) && (
                          <Chip
                            css={headerChipCss(twoColumnLayoutMediaQuery)}
                            icon={<InPersonIcon width={theme.fontSize.xl} />}
                          />
                        )}
                        {providerSupportsTelehealth(provider) && (
                          <Chip
                            css={headerChipCss(twoColumnLayoutMediaQuery)}
                            icon={<VideoChatIcon width={theme.fontSize.xl} />}
                          />
                        )}
                      </span>
                    </>
                  )}
                </div>
                {!twoColumnLayout && provider.pronouns && (
                  <span css={pronounCss(twoColumnLayoutMediaQuery)}>
                    {formatProviderPronouns(provider)}
                  </span>
                )}
                {provider.postnomials && provider.postnomials.length > 0 && (
                  <div css={headerPostnomialsCss}>
                    {provider.postnomials?.join(', ')}
                  </div>
                )}
                <div css={headerStateCss}>
                  <ExtendableTooltipList
                    highlights={[statesToDisplayNames[searchedState]]}
                    items={visiblePracticeStateNames.map(
                      (state: UnitedStates) => statesToDisplayNames[state]
                    )}
                  />
                </div>
                {twoColumnLayout && (
                  <div css={headerChipsContainerDesktopCss}>
                    {!isProviderTelehealthOnly(addresses) && (
                      <Chip
                        css={headerChipCss(twoColumnLayoutMediaQuery)}
                        icon={<InPersonIcon width={theme.fontSize.xl} />}
                        label="In-person sessions"
                      />
                    )}
                    {providerSupportsTelehealth(provider) && (
                      <Chip
                        css={headerChipCss(twoColumnLayoutMediaQuery)}
                        icon={<VideoChatIcon width={theme.fontSize.xl} />}
                        label="Virtual sessions"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            <section css={headerCarriersAndSpecialtiesCss}>
              <div className="provider-property-list hlx-typography-subbody">
                <AcceptsInsuranceIcon height={overviewBoxIconSize} />
                {onFileInNetworkCarrier &&
                (!inNetworkCarrierFromQueryString ||
                  onFileInNetworkCarrier.id ===
                    inNetworkCarrierFromQueryString.id) ? (
                  <React.Fragment>
                    <p className="hlx-typography-subbody mt-2">
                      <b>In-network with</b>
                    </p>
                    <p className="hlx-typography-subbody text-system-gray">
                      {onFileInNetworkCarrier.name}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p className="mt-2">
                      <b>In-network with</b>
                    </p>
                    {visibleCarriers.length === 0 ? (
                      <span css={{ color: theme.color.textGray }}>
                        None yet — coming soon
                      </span>
                    ) : (
                      <ListHighlightable
                        highlights={[highlightedCarrierText]}
                        items={highlightCarriersListItems}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
              <div className="provider-property-list">
                <SpecialtiesIcon height={overviewBoxIconSize} />
                <p className="hlx-typography-subbody mt-2">
                  <b>Specializes in</b>
                </p>
                {primarySpecialties.length > 0 ? (
                  <ListHighlightable
                    items={primarySpecialties.map(
                      (area) =>
                        area.patientDisplayName ?? area.clinicalDisplayName
                    )}
                    highlights={primarySpecialties
                      .filter((area) => {
                        return user.issueNames?.includes(area.key);
                      })
                      .map(
                        (area) =>
                          area.patientDisplayName ?? area.clinicalDisplayName
                      )}
                  />
                ) : (
                  <span css={{ color: theme.color.textGray }}>
                    None yet — coming soon
                  </span>
                )}
              </div>
            </section>

            {providerHighlights && providerHighlights.length > 0 && (
              <section css={highlightsCss}>
                {providerHighlights.map((highlight) => {
                  return (
                    <ProviderHighlightCallout
                      key={highlight.key}
                      highlight={highlight}
                    />
                  );
                })}
              </section>
            )}
            <>
              {!twoColumnLayout && isProfilePending && (
                <div css={pendingProfileContainerCss}>
                  <PendingProfileBookingMessage provider={provider} />
                </div>
              )}
            </>
            <div css={{ position: 'relative', paddingTop: theme.space.base }}>
              <StickyTabBar
                // keep an eye on the following -- it may cause unnecessary rerenders
                sections={Object.values(tabbableSections).map(
                  ({ id, tabName }) => ({
                    id,
                    name: tabName,
                  })
                )}
                stickyHeadersHeight={stickyHeadersHeight}
                ref={tabBarRef}
              />
              <IntroSection
                sectionId={tabbableSections.intro.id}
                provider={provider}
                css={sectionCss(stickyHeadersHeight)}
              />
              <Divider />
              {tabbableSections.style && (
                <>
                  <StyleSection
                    css={sectionCss(stickyHeadersHeight)}
                    firstName={provider.displayFirstName}
                    sectionId={tabbableSections.style.id}
                    styleTags={providerStyleTags.map((pst) => pst.styleTag)}
                  />
                  <Divider />
                </>
              )}
              {tabbableSections.cost && (
                <>
                  <CostAndCarriersSection
                    css={sectionCss(stickyHeadersHeight)}
                    provider={provider}
                    frontEndCarriers={visibleFrontEndCarriers}
                    sectionId={tabbableSections.cost.id}
                  />
                  <Divider />
                </>
              )}
              <FactsSection
                isMSCAgoraEnabled={isMSCAgoraEnabled}
                addresses={addresses}
                providerCredentials={filteredProviderCredentials}
                ageGroups={ageGroups}
                css={sectionCss(stickyHeadersHeight)}
                modalities={treatmentApproaches}
                otherTreatmentAreas={secondarySpecialties}
                provider={provider}
                sectionId={tabbableSections.more.id}
              />
            </div>
          </div>
          {twoColumnLayout &&
            (isProfilePending ? (
              <div css={pendingProfileContainerCss}>
                <PendingProfileBookingMessage provider={provider} />
              </div>
            ) : (
              <div css={costAndAvailabilityCss}>
                <ProviderPriceAndAvailability
                  addresses={addresses}
                  provider={provider}
                  sessionId={sessionId || undefined}
                />
              </div>
            ))}
        </div>
      </MaxWidthSection>
      {!twoColumnLayout && (
        <div css={bookingBannerCss}>
          {tabbableSections.cost && ( // same check as isProfilePending
            <BookingBanner
              addresses={addresses}
              costScrollHref={tabbableSections.cost.id}
              scrollOffset={stickyHeadersHeight * -1 - 16}
              provider={provider}
            />
          )}
        </div>
      )}
    </PageWrapper>
  );
};

/* Styles */
const alertBannerCss = css`
  display: flex;
  justify-content: center;
  background-color: ${theme.color.backgroundGray};
  color: ${theme.color.black};
  a {
    color: ${theme.color.black};
    text-decoration: underline;
  }
  .MuiAlert-icon {
    color: ${theme.color.black};
  }
`;

const bookingBannerCss = css`
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
`;

const costAndAvailabilityCss = css`
  // align-self is required because this is rendered in a grid and default
  // behavior is for height of element to be height of tallest element
  align-self: flex-start;
  margin-bottom: ${theme.space.xl};
  padding-top: ${theme.space.sm};
  position: sticky;
  top: calc(var(${HEADER_HEIGHT_VARIABLE}) + ${theme.space.base});
`;

const headerBackgroundCss = (twoColumnLayoutMediaQuery: string) => css`
  border-radius: 0;
  height: 110px;
  ${twoColumnLayoutMediaQuery} {
    height: 170px;
  }
`;
const headerCss = (twoColumnLayoutMediaQuery: string) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xl};
  margin-bottom: ${theme.space.base};
  ${twoColumnLayoutMediaQuery} {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: ${theme.space.xl};
  }
`;

const headerCarriersAndSpecialtiesCss = css`
  display: flex;
  gap: ${theme.space.base};
  & .provider-property-list {
    width: 50%;
    border: 1px solid ${theme.color.border};
    border-radius: 4px;
    padding: ${theme.space.sm};
    font-size: ${theme.fontSize.sm};
  }
  & h4 {
    color: ${theme.color.black};
    font-family: ${theme.fontFamily.postGrotesk};
    font-size: ${theme.fontSize.base};
    font-weight: ${theme.fontWeight.base};
    margin: 0;
    margin-bottom: ${theme.space.xs2};
    ${theme.media.muiMedium}: {
      font-size: ${theme.fontSize.xl};
    }
  }
`;

const headerChipsContainerDesktopCss = css`
  margin-top: ${theme.space.base};
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.space.xs};
`;
const headerChipsContainerMobileCss = css`
  margin-left: ${theme.space.xl};
  display: flex;
  gap: ${theme.space.xs};
`;
const headerChipCss = (twoColumnLayoutMediaQuery: string) => css`
  background-color: ${theme.color.primaryBackground};
  border-radius: 5px;
  color: ${theme.color.black};
  font-size: ${theme.fontSize.sm};
  height: ${theme.fontSize.xl4};
  padding: ${theme.space.xs};
  .MuiChip-icon {
    color: ${theme.color.primary};
    font-size: ${theme.fontSize.xl};
    margin: 0;
  }
  .MuiChip-label {
    padding: 0;
  }
  ${twoColumnLayoutMediaQuery} {
    top: 0;
    gap: ${theme.space.sm};
  }
`;

const headerPhotoCss = (twoColumnLayoutMediaQuery: string) => css`
  height: 160px;
  margin-bottom: -64px;
  position: relative;
  top: -64px;
  width: 160px;
  ${twoColumnLayoutMediaQuery} {
    height: 240px;
    margin-bottom: -80px;
    top: -80px;
    width: 240px;
  }
`;
const headerPostnomialsCss = css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.base};
  margin-bottom: ${theme.space.xs2};
  margin-top: ${theme.space.xs};
`;
const headerStateCss = css`
  color: ${theme.color.textGray};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.base};
`;

const highlightsCss = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.base};
  margin-top: ${theme.space.xl2};
`;

const maxWidthSectionCss = (twoColumnLayoutMediaQuery: string) => css`
  padding-bottom: ${theme.space.xl4};
  ${twoColumnLayoutMediaQuery} {
    padding: 0 ${theme.space.xl4};
  }
`;

const profileContainerCss = (twoColumnLayoutMediaQuery: string) => css`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: ${theme.space.xl};
  ${twoColumnLayoutMediaQuery} {
    grid-template-columns: 1fr minmax(400px, min-content);
  }
`;

const pronounCss = (twoColumnLayoutMediaQuery: string) => css`
  font-size: ${theme.fontSize.base};
  font-family: ${theme.fontFamily.brandText};
  ${twoColumnLayoutMediaQuery} {
    margin-left: ${theme.space.xs};
    margin-top: ${theme.space.lg};
  }
`;

const sectionCss = (scrollMarginTop: number) => css`
  margin-bottom: ${theme.space.xl2};
  scroll-margin-top: calc(${scrollMarginTop}px + ${theme.space.base});
`;

/* Helper functions */
const getProviderFromSlug = async (
  slug: string,
  axiosConfig: AxiosRequestConfig
): Promise<ProviderRead | undefined> => {
  const numericSlug = Number(slug);

  try {
    // if the slug is numeric, we'll assume it's a provider ID
    if (!isNaN(numericSlug)) {
      return await ProviderApi.getProvider(numericSlug, axiosConfig);
    } else {
      return await ProviderApi.getProviderBySlug(slug, axiosConfig);
    }
  } catch (err) {
    logException(err);
    return undefined;
  }
};

const getVisibleCarriersForProvider = (
  providerCarriers: ProviderFrontEndCarrierRead[],
  isMscAgoraEnabled: boolean,
  searchableFrontEndCarriers: FrontEndCarrierNested[],
  searchedProviderLicenseState?: ProviderLicenseStateRead
): FrontEndCarrierNested[] => {
  const carriers = convertProviderCarriersToSearchableCarriers(
    providerCarriers
      .filter(
        (providerCarrier) =>
          !providerCarrier.hiddenFromProfile &&
          isAppointmentReady(providerCarrier)
      )
      .filter((carrier) => {
        // If provider is live in searched state, only return carriers for that state
        if (
          isMscAgoraEnabled &&
          !!searchedProviderLicenseState &&
          isProviderLiveInState(searchedProviderLicenseState)
        ) {
          return (
            carrier.providerLicenseState.state ===
            searchedProviderLicenseState.state
          );
        }

        return true;
      }),
    searchableFrontEndCarriers
  );

  return sortBy(carriers, (carrier) => carrier.name.toLowerCase());
};

const parsePreferredCarrierId = (
  preferredCarrierIdFromQuery?: string | string[]
): number | null => {
  if (
    Array.isArray(preferredCarrierIdFromQuery) ||
    preferredCarrierIdFromQuery === undefined
  ) {
    return null;
  }

  const numericPreferredCarrierId = parseInt(preferredCarrierIdFromQuery);

  if (isNaN(numericPreferredCarrierId)) return null;

  return numericPreferredCarrierId;
};

const parseSessionId = (
  sessionIdFromQuery?: string | string[]
): number | null => {
  if (Array.isArray(sessionIdFromQuery) || !sessionIdFromQuery) {
    return null;
  }

  const numericSessionId = parseInt(sessionIdFromQuery);

  if (isNaN(numericSessionId)) return null;

  return numericSessionId;
};

const parseIsBlueCard = (
  isBlueCardFromQuery?: string | string[]
): boolean | null => {
  if (Array.isArray(isBlueCardFromQuery) || isBlueCardFromQuery === undefined) {
    return null;
  }
  return isBlueCardFromQuery === 'true';
};

export const getServerSideProps: GetServerSideProps<{
  addresses: ProviderAddressRead[];
  ageGroups: string[];
  focusAreas: SpecialtyNested[];
  isPreviewing: boolean;
  otherTreatmentAreas: SpecialtyNested[];
  provider: ProviderRead;
  providerBookingPreference: ProviderSpecialtyBookingPreference;
  providerFrontEndCarriers: ProviderFrontEndCarrierRead[];
  providerStyleTags: ProviderStyleTagRead[];
  preferredCarrierId: number | null;
  isBlueCard: boolean | null;
  treatmentApproaches: ModalityRead[];
  searchableCarriers: FrontEndCarrierNested[];
  providerCredentials: ProviderCredentialRead[];
  sessionId: number | null;
  isMSCAgoraEnabled: boolean;
  isProfilePending: boolean;
}> = async ({
  req,
  res,
  query: { slug, preferredCarrierId, isBlueCard, sessionId },
}: GetServerSidePropsContextWithUser) => {
  const queryClient = makeQueryClient();
  const requestLaunchDarklyContext = buildLaunchDarklyContextFromRequest(req);
  const isMSCAgoraEnabled = await getFlag(
    'multi-state-credentialing-agora',
    requestLaunchDarklyContext
  );

  try {
    if (typeof slug === 'undefined' || Array.isArray(slug)) {
      return {
        notFound: true,
        props: {},
      };
    }

    const cookie = getAuthCookie(req, res);

    const axiosConfig: AxiosRequestConfig = {
      headers: cookie ? { cookie } : {},
    };

    const provider = await getProviderFromSlug(slug, axiosConfig);

    if (!provider) {
      return {
        notFound: true,
        props: {},
      };
    }

    if (provider.id.toString() === slug) {
      return {
        redirect: {
          destination: `/providers/${provider.slug}`,
          permanent: true,
        },
        props: {},
      };
    }

    const user = req.userMeData || (await UserApi.getUserMe({}, axiosConfig));

    // don't query for user roles if user is anonymous
    const userRoles = !!user.email
      ? await UserApi.getRoles(user.id, axiosConfig)
      : [];
    const isAtlasUser = 'ATLAS_USER' in userRoles;

    const hasProviderAccess =
      user.providerId === provider.id ||
      user.groupPracticeUsers?.some(
        (gp) => gp.groupPracticeId === provider.groupPracticeId
      ) ||
      isAtlasUser;

    const isPreviewing = hasProviderAccess
      ? provider.earliestActiveLiveOn
        ? new Date(provider.earliestActiveLiveOn) > new Date()
        : true
      : false;

    const {
      providerAddresses,
      providerSpecialties,
      providerModalities,
      providerAgeGroups,
      providerFrontEndCarriers,
      providerStyleTags,
      bookingPreference,
      frontEndCarriers,
      providerCredentials,
      isProfilePending,
    } = await ProviderApi.getProviderProfile(provider.id, axiosConfig);

    queryClient.prefetchQuery(
      getCarriersAndProviderPriceCacheKey({
        providerId: provider.id,
        patientUserId: user.id,
        activeUserInsuranceId: user.activeUserInsuranceId,
      }),
      () =>
        Promise.all([
          frontEndCarriers,
          ProviderApi.getProviderPrice(provider.id, user.id, axiosConfig),
        ])
    );

    queryClient.setQueryData(
      [PROVIDER_BOOKING_PREFERENCE_CACHE_KEY],
      bookingPreference
    );

    const treatmentApproaches = providerModalities.map(
      (providerModality) => providerModality.modality
    );

    const ageGroups = sortBy(providerAgeGroups, (p) => p.ageGroup.minAge).map(
      (providerAgeGroup) => providerAgeGroup.ageGroup.displayName
    );

    const focusAreas = providerSpecialties
      .filter(
        (providerSpecialty) =>
          providerSpecialty.isFocusArea &&
          providerSpecialty.specialty.availableToPatients
      )
      .map((providerSpecialty) => providerSpecialty.specialty);

    const otherTreatmentAreas = providerSpecialties
      .filter(
        (providerSpecialty) =>
          !providerSpecialty.isFocusArea &&
          providerSpecialty.specialty.availableToPatients
      )
      .map((providerSpecialty) => providerSpecialty.specialty);

    // inherited, but this is likely not awaited because it's primarily used for analytics, so we fire and forget!
    ProviderApi.createProfileView(provider.id, axiosConfig);

    return {
      props: {
        addresses: providerAddresses,
        ageGroups,
        providerCredentials,
        dehydratedState: dehydrate(queryClient),
        focusAreas,
        isPreviewing,
        otherTreatmentAreas,
        provider,
        providerBookingPreference: bookingPreference.bookingPreference,
        providerFrontEndCarriers,
        providerStyleTags,
        preferredCarrierId: parsePreferredCarrierId(preferredCarrierId),
        isBlueCard: parseIsBlueCard(isBlueCard),
        treatmentApproaches,
        searchableCarriers: frontEndCarriers,
        sessionId: parseSessionId(sessionId),
        isMSCAgoraEnabled,
        isProfilePending,
      },
    };
  } catch (err) {
    logException(err);
    return {
      redirect: {
        destination: '/search',
        permanent: false,
      },
    };
  }
};

export default withStores(ProviderProfilePage);
