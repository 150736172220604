import { css } from '@emotion/react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import MobileStepper from '@mui/material/MobileStepper';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

import { CredentialType } from '@headway/api/models/CredentialType';
import { LicenseTypes } from '@headway/api/models/LicenseTypes';
import { ModalityRead } from '@headway/api/models/ModalityRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderCredentialRead } from '@headway/api/models/ProviderCredentialRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SpecialtyNested } from '@headway/api/models/SpecialtyNested';
import {
  AgeGroups as AgeGroupsIcon,
  Education as EducationIcon,
  Ethnicities as EthnicitiesIcon,
  Gender as GenderIcon,
  Languages as LanguagesIcon,
  LicenseTypes as LicenseTypesIcon,
  Modalities as ModalitiesIcon,
  Specialties as SpecialtiesIcon,
} from '@headway/icons/dist/provider';
import { formatProviderGenderAndGenderStatus } from '@headway/shared/constants/gender';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { licenseTypes } from '@headway/shared/utils/marketLicenseTypes';
import { getFormattedEducation } from '@headway/shared/utils/providers';
import { theme } from '@headway/ui/theme';

import { ProviderMap } from '../ProviderMap';
import { bodyTextCss, StyledSectionHeader } from './Text';

type Fact = {
  label: string;
  icon: (props: SvgIconProps) => JSX.Element;
};

const getFacts = ({
  providerCredentials,
  ageGroups,
  modalities,
  otherTreatmentAreas,
  provider,
  isMSCAgoraEnabled,
}: {
  ageGroups: string[];
  providerCredentials: ProviderCredentialRead[];
  modalities: ModalityRead[];
  otherTreatmentAreas: SpecialtyNested[];
  provider: ProviderRead;
  isMSCAgoraEnabled: boolean;
}): Fact[] => {
  const facts: Fact[] = [];

  const listFormatter = new Intl.ListFormat(undefined, { style: 'long' });

  const formattedEducation = getFormattedEducation(provider);
  if (formattedEducation) {
    facts.push({
      icon: EducationIcon,
      label: formattedEducation,
    });
  }

  if (isMSCAgoraEnabled && providerCredentials.length > 0) {
    const licenseTypeMap = providerCredentials
      .filter(
        (credential) => credential.credentialType === CredentialType.LICENSE
      )
      .reduce(
        (licenseTypeMap, license) => {
          if (!license.licenseType) {
            return licenseTypeMap;
          }

          const displayState = statesToDisplayNames[license.state];

          if (licenseTypeMap[license.licenseType]) {
            licenseTypeMap[license.licenseType].push(displayState);
          } else {
            licenseTypeMap[license.licenseType] = [displayState];
          }

          return licenseTypeMap;
        },
        {} as { [key in LicenseTypes]: string[] }
      );

    const formattedLicenses = Object.entries(licenseTypeMap).map(
      ([type, states]) => {
        const licenseTypeDisplay = licenseTypes[type]?.displayName || type;
        return `${licenseTypeDisplay} (${states.join(', ')})`;
      }
    );

    facts.push({
      icon: LicenseTypesIcon,
      label: `License type: ${formattedLicenses.join(', ')}`,
    });
  } else if (provider.licenseType) {
    facts.push({
      icon: LicenseTypesIcon,
      label: `License type: ${
        licenseTypes[provider.licenseType]
          ? licenseTypes[provider.licenseType].displayName
          : provider.licenseType
      }`,
    });
  }
  if (provider.gender) {
    facts.push({
      icon: GenderIcon,
      label: `Gender: ${formatProviderGenderAndGenderStatus(
        provider.gender,
        provider.genderStatus
      )}`,
    });
  }
  if (provider.ethnicity?.length) {
    facts.push({
      icon: EthnicitiesIcon,
      label: `Ethnicity: ${listFormatter.format(provider.ethnicity)}`,
    });
  }
  if (provider.languages?.length) {
    facts.push({
      icon: LanguagesIcon,
      label: `Languages: ${listFormatter.format(provider.languages)}`,
    });
  }
  if (ageGroups.length) {
    facts.push({
      icon: AgeGroupsIcon,
      label: `Works with: ${listFormatter.format(ageGroups)}`,
    });
  }
  if (otherTreatmentAreas.length) {
    const treatmentAreas = otherTreatmentAreas.map(
      (area) => area.patientDisplayName ?? area.clinicalDisplayName
    );
    facts.push({
      icon: SpecialtiesIcon,
      label: `More specialties: ${listFormatter.format(treatmentAreas)}`,
    });
  }
  if (modalities.length) {
    const modalityNames = modalities.map(
      (modality) => modality.patientDisplayName
    );
    facts.push({
      icon: ModalitiesIcon,
      label: `Modalities: ${listFormatter.format(modalityNames)}`,
    });
  }
  return facts;
};

type Props = {
  addresses: ProviderAddressRead[];
  ageGroups: string[];
  className?: string;
  modalities: ModalityRead[];
  otherTreatmentAreas: SpecialtyNested[];
  provider: ProviderRead;
  sectionId: string;
  providerCredentials: ProviderCredentialRead[];
  isMSCAgoraEnabled: boolean;
};

const FactsSection: React.FC<Props> = ({
  addresses,
  ageGroups,
  className,
  modalities,
  otherTreatmentAreas,
  provider,
  sectionId,
  providerCredentials,
  isMSCAgoraEnabled,
}) => {
  const facts = getFacts({
    isMSCAgoraEnabled,
    providerCredentials,
    ageGroups,
    modalities,
    otherTreatmentAreas,
    provider,
  });

  const activeAddresses = addresses.filter((address) => address.isActive);

  return (
    <section id={sectionId} className={className}>
      <StyledSectionHeader>
        More about {provider.displayFirstName}
      </StyledSectionHeader>
      <div css={contentContainerCss}>
        <div css={factsContainerCss}>
          {facts.map(({ icon: Icon, label }) => (
            <div css={factCss} key={label}>
              <div css={iconContainerCss}>
                <Icon width={theme.fontSize.xl} />
              </div>
              <span css={bodyTextCss}>{label}</span>
            </div>
          ))}
        </div>
        {activeAddresses.length ? (
          <ProviderLocations addresses={activeAddresses} />
        ) : null}
      </div>
    </section>
  );
};

export const ProviderLocations: React.FC<{
  addresses: ProviderAddressRead[];
}> = ({ addresses }) => {
  const [isMapLoaded, setIsMapLoaded] = React.useState<boolean>(false);
  const [currentAddressIndex, setCurrentAddressIndex] =
    React.useState<number>(0);

  const { streetLine1, streetLine2, city, state, zipCode } =
    addresses[currentAddressIndex];

  return (
    <div css={providerLocationsContainerCss}>
      <div css={headerAndNavContainerCss}>
        <h4 css={locationsHeaderCss}>Location{addresses.length > 1 && 's'}</h4>
        {/* paginate if more than 1 address */}
        {addresses.length > 1 ? (
          <MobileStepper
            css={stepperCss}
            variant="text"
            steps={addresses.length}
            position="static"
            activeStep={currentAddressIndex}
            nextButton={
              <IconButton
                aria-label="Next address"
                css={{ padding: 0 }}
                disabled={currentAddressIndex === addresses.length - 1}
                onClick={() => {
                  setCurrentAddressIndex(
                    (currentAddressIndex) => currentAddressIndex + 1
                  );
                }}
                size="large"
              >
                <ChevronRight />
              </IconButton>
            }
            backButton={
              <IconButton
                aria-label="Previous address"
                css={{ padding: 0 }}
                disabled={currentAddressIndex === 0}
                onClick={() => {
                  setCurrentAddressIndex(
                    (currentAddressIndex) => currentAddressIndex - 1
                  );
                }}
                size="large"
              >
                <ChevronLeft />
              </IconButton>
            }
          />
        ) : null}
      </div>
      <div>
        <span css={locationsAddressCss}>
          {`${streetLine1}${streetLine2 ? ` ${streetLine2}` : ''}`} <br />{' '}
          {`${city}, ${state} ${zipCode}`}
        </span>
      </div>
      <div css={{ marginTop: theme.space.lg }}>
        <div
          css={{
            opacity: isMapLoaded ? 1 : 0,
            transition: 'opacity 200ms ease-in',
          }}
        >
          <ProviderMap
            className="aspect-[5/3] w-full"
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3`}
            loadingElement={<div css={mapCss} />}
            containerElement={<div />}
            mapElement={<div css={mapCss} />}
            markers={
              addresses &&
              addresses.map((x) => ({
                lat: x.lat,
                lng: x.lon,
              }))
            }
            onTilesLoaded={() => setIsMapLoaded(true)}
            defaultCenter={{
              lat: addresses[currentAddressIndex].lat,
              lng: addresses[currentAddressIndex].lon,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const contentContainerCss = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: ${theme.space.lg};
  column-gap: ${theme.space.lg};
  ${theme.media.muiMedium} {
    flex-direction: row;
  }
`;

const headerAndNavContainerCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.space.sm};
`;

const stepperCss = css`
  padding: 0;
  white-space: nowrap;
  font-size: ${theme.fontSize.sm};
  ${theme.media.muiMedium} {
    font-size: ${theme.fontSize.base};
  }
`;

const locationsHeaderCss = css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: 0;
  margin-top: 0;
  ${theme.media.muiMedium} {
    flex-direction: row;
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.regular};
  }
`;

const locationsAddressCss = css`
  ${bodyTextCss}
  font-size: ${theme.fontSize.sm};
  ${theme.media.muiMedium} {
    font-size: ${theme.fontSize.base};
  }
`;

const iconContainerCss = css`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  & path {
    fill: ${theme.color.black};
    fill-opacity: 0.87;
  }
`;

const providerLocationsContainerCss = css`
  width: 100%;
  ${theme.media.muiMedium} {
    width: 40%;
  }
`;

const mapCss = css`
  width: 100%;
  aspect-ratio: 5 / 3;
`;

const factsContainerCss = css`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.space.sm};
  width: 100%;
  ${theme.media.muiMedium} {
    width: 60%;
  }
`;

const factCss = css`
  display: flex;
  align-items: flex-start;
  column-gap: ${theme.space.sm};
`;

export { FactsSection };
