/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum GenderStatus {
    CISGENDER = 'cisgender',
    NOT_DISCLOSED = 'not_disclosed',
    TRANSGENDER = 'transgender'
}


export const GenderStatusMetadata: EnumMetadata<GenderStatus> = {
    name: 'GenderStatus',
    values: {
            CISGENDER: 'cisgender',
            NOT_DISCLOSED: 'not_disclosed',
            TRANSGENDER: 'transgender'
    }
}
