import { css } from '@emotion/react';
import React from 'react';

import { Logo } from '@headway/ui/landing';
import { theme } from '@headway/ui/theme';

const logoCss = (fontColor: string) => css`
  border-radius: 4px;
  border: 1px solid ${theme.color.border};
  color: ${fontColor};
  font-size: ${theme.fontSize.xl};
  font-weight: ${theme.fontWeight.bold};
  padding: ${theme.space.base};
`;

const payerToLogo = {
  aetna: (_?: string) => <Logo css={logoCss(theme.color.aetna)}>Aetna</Logo>,
  anthem: (carrier?: string) => (
    <Logo css={logoCss(theme.color.anthem)}>{carrier ?? 'Anthem'}</Logo>
  ),
  'blue cross blue shield': (carrier?: string) => (
    <Logo css={logoCss(theme.color.bcbs)}>
      {carrier ?? 'BlueCross BlueShield'}
    </Logo>
  ),
  cigna: (_?: string) => <Logo css={logoCss(theme.color.cigna)}>Cigna</Logo>,
  'united healthcare': (_?: string) => (
    <Logo css={logoCss(theme.color.uhc)}>UnitedHealthcare</Logo>
  ),
  oxford: (_?: string) => <Logo css={logoCss(theme.color.uhc)}>Oxford</Logo>,
  unitedhealthcare: (_?: string) => (
    <Logo css={logoCss(theme.color.uhc)}>UnitedHealthcare</Logo>
  ),
  oscar: (_?: string) => <Logo css={logoCss(theme.color.oscar)}>Oscar</Logo>,
};

export function getGenericName(payerName: string) {
  for (const [lowerPayerName] of Object.entries(payerToLogo)) {
    const payerRegex = new RegExp(lowerPayerName, 'i');
    if (payerRegex.test(payerName)) return lowerPayerName;
  }
  return payerName;
}

// TODO: Switch over to PayerLogo when we're allowed to use them again
export const PayerTextLogo: React.FC<{ payerName: string }> = ({
  payerName,
}) => {
  for (const [lowerPayerName, PayerLogo] of Object.entries(payerToLogo)) {
    const payerRegex = new RegExp(lowerPayerName, 'i');
    if (payerRegex.test(payerName))
      return <div css>{PayerLogo(payerName)}</div>;
  }

  return <Logo css={logoCss(theme.color.black)}>{payerName}</Logo>;
};
