import { css } from '@emotion/react';
import React from 'react';

import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { theme } from '@headway/ui/theme';

import { IAuthStore, IUiStore, withStores } from '../../../stores/withStores';
import { getGenericName, PayerTextLogo } from '../../Payers/PayerTextLogo';
import { PriceEstimate } from './ProviderPriceAndAvailability';
import { StyledSectionHeader } from './Text';

type Props = {
  AuthStore: IAuthStore;
  className?: string;
  frontEndCarriers: FrontEndCarrierNestedWithName[];
  provider: ProviderRead;
  sectionId: string;
  UiStore: IUiStore;
};

export type FrontEndCarrierNestedWithName = Omit<
  FrontEndCarrierNested,
  'name'
> & {
  name: string;
};

const CostAndCarriersSectionImpl: React.FC<Props> = ({
  AuthStore,
  className,
  frontEndCarriers,
  provider,
  sectionId,
}) => {
  return (
    <section id={sectionId} className={className}>
      <PriceEstimate css={estimateContainerCss} provider={provider} />
      {frontEndCarriers.length > 0 && (
        <AcceptedInsuranceCarriers
          frontEndCarriers={frontEndCarriers}
          provider={provider}
        />
      )}
    </section>
  );
};

const AcceptedInsuranceCarriers: React.FC<{
  frontEndCarriers: FrontEndCarrierNestedWithName[];
  provider: ProviderRead;
}> = ({ frontEndCarriers, provider }) => {
  const carriers = frontEndCarriers.map((carrier) => carrier.name);
  const dedupedCarriers = Array.from(new Set(carriers));
  return (
    <div>
      <StyledSectionHeader>
        Insurance carriers {provider.displayFirstName} accepts
      </StyledSectionHeader>
      <div css={logoContainerCss}>
        {dedupedCarriers.map((carrierName) => (
          <PayerTextLogo payerName={carrierName} key={carrierName} />
        ))}
      </div>
    </div>
  );
};

const logoContainerCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${theme.space.sm};
`;

const estimateContainerCss = css`
  border: none;
  padding-bottom: 0;
`;

export const CostAndCarriersSection = withStores(CostAndCarriersSectionImpl);
