import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { GlossaryTerm } from '@headway/helix/GlossaryTerm';

interface ExtendableTooltipListProps {
  items: string[];
  highlights?: string[];
}

const listFormatter = new Intl.ListFormat(undefined, { style: 'long' });

export const ExtendableTooltipList = ({
  items,
  highlights = [],
}: ExtendableTooltipListProps) => {
  const sorted = sortBy(items, (item) => item.toLowerCase());
  const [matches, others] = partition(sorted, (i) => {
    const isMatch = highlights.includes(i);
    return isMatch;
  });

  const sortedItemsList = [...matches, ...others].slice(0, 3);

  if (items.length > 3) {
    sortedItemsList.push('more');
  }

  return (
    <div className="hlx-typography-subbody text-system-gray mb-2">
      {listFormatter
        .formatToParts(sortedItemsList)
        .map(({ type, value }, i) => {
          if (type === 'literal') {
            return value;
          }
          const isHighlighted = highlights.includes(value);
          if (isHighlighted) {
            return <b className="text-system-black">{value}</b>;
          }

          if (value === 'more') {
            return (
              <GlossaryTerm term="more">
                {others.slice(3).join(', ')}
              </GlossaryTerm>
            );
          }

          return value;
        })}
    </div>
  );
};
