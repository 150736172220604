import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import { Button } from '@headway/helix/Button';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  buttonCss?: object;
  maxLines: number;
  buttonLabel: string;
};

export const ExpandableText = ({
  children,
  buttonCss = {},
  maxLines,
  buttonLabel,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(true);

  React.useEffect(() => {
    const containerElement = containerRef.current;
    if (!containerElement) return;
    const expandableTextContainer = containerElement.querySelector(
      '.expandable-text-container'
    );
    if (!expandableTextContainer) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const computedHeight = entry.target.getBoundingClientRect().height;
        const scrollHeight = entry.target.scrollHeight;

        // Give 1px of wiggle room to account for line-clamp strange behavior
        // in the 'ListHighlightable' component, where scrollHeight
        // is being calculated as 1px more than content height.
        setIsTruncated(scrollHeight > computedHeight + 1);
      }
    });

    if (expandableTextContainer) {
      observer.observe(expandableTextContainer);
    }

    return () => {
      if (expandableTextContainer) {
        observer.unobserve(expandableTextContainer);
      }
    };
  }, []);

  return (
    <div ref={containerRef}>
      <div
        className={clsx(
          'expandable-text-container',
          isTruncated && `text-ellipsis break-words`,
          isTruncated && {
            'line-clamp-1': maxLines === 1,
            'line-clamp-2': maxLines === 2,
            'line-clamp-3': maxLines === 3,
            'line-clamp-4': maxLines === 4,
            'line-clamp-5': maxLines === 5,
            'line-clamp-6': maxLines >= 6,
          },
          'hlx-typography-subbody text-system-gray mb-2'
        )}
      >
        {children}
      </div>

      {isTruncated && (
        <div css={{ ...buttonCss }}>
          <Button
            variant="link"
            size="medium"
            onPress={() => {
              setIsTruncated(false);
            }}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};
