// eslint-disable-next-line no-restricted-imports
import { isCareAppDestination } from '@headway/shared/utils/request';

export function getFullURLForPath(path: string) {
  const originalUrl = new URL(path, process.env.NEXT_PUBLIC_CARE_URL);

  if (isCareAppDestination(originalUrl)) {
    return originalUrl.href;
  }
  return new URL(path, process.env.NEXT_PUBLIC_MARKETING_URL!).href;
}
