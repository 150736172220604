import React from 'react';

import {
  GoogleMapEmbed,
  GoogleMapsApiProvider,
  Marker,
  useMap,
} from '@headway/ui/GoogleMap';

export const ProviderMap = (props) => {
  return (
    <GoogleMapsApiProvider
      apiKey={process.env.GOOGLE_MAPS_API_KEY}
      libraries={['places']}
      version="3"
    >
      <GoogleMapEmbed
        defaultZoom={14}
        defaultCenter={{
          lat:
            (props.markers &&
              props.markers.length &&
              Number(props.markers[0].lat)) ||
            40.7128,
          lng:
            (props.markers &&
              props.markers.length &&
              Number(props.markers[0].lng)) ||
            -74.006,
        }}
        scrollwheel={true}
        zoomControl={false}
        fullscreenControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        {...props}
      >
        <PanToCenter center={props.defaultCenter} />
        {typeof window !== 'undefined' &&
          window.google?.maps?.Size &&
          props.markers &&
          props.markers.map((marker, idx) => {
            return (
              <Marker
                icon={{
                  url: '/marker-selected.svg',
                  // eslint-disable-next-line no-undef
                  scaledSize: new google.maps.Size(50, 50),
                }}
                position={{
                  lat: Number(marker.lat),
                  lng: Number(marker.lng),
                }}
                key={idx}
              />
            );
          })}
      </GoogleMapEmbed>
    </GoogleMapsApiProvider>
  );
};

function PanToCenter({ center }) {
  const map = useMap();

  React.useEffect(() => {
    map.panTo({
      lat: center.lat,
      lng: center.lng,
    });
  }, [center.lat, center.lng, map]);

  return null;
}
