import { css } from '@emotion/react';
import { ArrowBackIos } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import { useSearchParams } from 'next/navigation';
import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';

import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { useProviderAvailability } from '@headway/shared/hooks/useProviderAvailability';
import { logException } from '@headway/shared/utils/sentry';
import { Button } from '@headway/ui';
import { Modal } from '@headway/ui/Modal';
import { theme } from '@headway/ui/theme';
import { notifyError, notifyWarning } from '@headway/ui/utils/notify';

import { useRouter } from '../../../hooks/useRouter';
import { IAuthStore, IUiStore, withStores } from '../../../stores/withStores';
import { BookingAvailability } from '../BookingModal/BookingAvailability';
import { getAvailabilityBookingUrl } from '../ProfilePage/ProviderPriceAndAvailability';
import {
  trackAvailabilitySlotSelected,
  trackSelectAvailabilityCompleted,
  trackSelectAvailabilityStarted,
} from './analyticsEvents';
import { PriceEstimate } from './ProviderPriceAndAvailability';

interface Props {
  addresses: ProviderAddressRead[];
  AuthStore: IAuthStore;
  costScrollHref: string;
  provider: ProviderRead;
  scrollOffset?: number;
  UiStore: IUiStore;
}

const BookingBannerImpl = ({
  addresses,
  AuthStore,
  costScrollHref,
  scrollOffset,
  provider,
}: Props) => {
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);

  const router = useRouter();
  const searchParams = useSearchParams();
  const providerAvailabilityQuery = useProviderAvailability(provider, {
    onError: (err: unknown) => {
      notifyError(
        `We couldn't determine ${provider.displayName}'s availability. Please refresh the page to try again.`
      );
      logException(err);
    },
  });

  const nextAvailableOpening =
    providerAvailabilityQuery.data && providerAvailabilityQuery.data.length > 0
      ? providerAvailabilityQuery.data[0]
      : undefined;
  const availabilityLoading = providerAvailabilityQuery.isLoading;
  const availabilityFetchError = providerAvailabilityQuery.isError;

  const onCheckAvailabilityClick = () => {
    trackSelectAvailabilityStarted({});
    setShowAvailabilityModal(true);
  };

  if (availabilityLoading) {
    return (
      <div css={containerCss()}>
        <div>
          <Skeleton width="100px" />
          <Skeleton width="120px" />
        </div>
      </div>
    );
  }

  return (
    <div css={containerCss(!!nextAvailableOpening)}>
      <div css={appointmentInfoCss}>
        <div css={{ marginBottom: theme.space.xs2 }}>
          <ScrollLink
            css={costLinkCss}
            duration={200}
            hashSpy
            href={costScrollHref}
            offset={scrollOffset}
            saveHashHistory={false}
            smooth
            spy
            to={costScrollHref}
          >
            <PriceEstimate condensedPriceOnly provider={provider} />
          </ScrollLink>
        </div>
        {nextAvailableOpening ? (
          <div css={nextAvailableCss()}>
            Next available:{' '}
            {moment(nextAvailableOpening.startDate).format('MMM D')}
          </div>
        ) : !availabilityFetchError ? (
          <div css={nextAvailableCss(false)}>
            {provider.displayName} is not available in the next 2 weeks
          </div>
        ) : null}
      </div>

      {nextAvailableOpening || availabilityFetchError ? (
        <Button
          color="primary"
          onClick={onCheckAvailabilityClick}
          size="large"
          variant="contained"
          data-dd-action-name="Check Availibility Button"
        >
          Check availability
        </Button>
      ) : null}

      <Modal
        dividers={false}
        closeable={false}
        open={showAvailabilityModal}
        maxWidth="md"
      >
        <div css={modalContainerCss}>
          <IconButton
            aria-label="Back"
            onClick={() => {
              setShowAvailabilityModal(false);
            }}
            css={backIconCss}
          >
            <ArrowBackIos />
          </IconButton>
          <p css={{ fontSize: theme.fontSize.xl, marginBottom: '0px' }}>
            Book Appointment
          </p>
        </div>
        <div
          css={{
            marginTop: theme.space.base,
            paddingTop: theme.space.lg,
          }}
        >
          <h4 css={{ marginBottom: theme.space.base }}>
            {provider.displayName
              ? `${provider.displayName}'s availability`
              : 'Availability'}
          </h4>
          <BookingAvailability
            addresses={addresses}
            onAvailabilitySlotClick={(autoSelected) => {
              trackAvailabilitySlotSelected({
                autoSelected,
              });
            }}
            onError={() =>
              notifyWarning(
                `We couldn't determine ${provider.displayName}'s availability. Please refresh the page to try again.`
              )
            }
            onNext={(availabilityEvent) => {
              trackSelectAvailabilityCompleted({});
              const bookingUrl = getAvailabilityBookingUrl({
                availability: availabilityEvent,
                slug: provider.slug,
                algoliaQueryId: searchParams.get('queryId') || '',
              });
              router.push(bookingUrl);
            }}
            provider={provider}
            user={AuthStore.user}
          />
        </div>
      </Modal>
    </div>
  );
};

/* Styles */
const appointmentInfoCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const containerCss = (hasAvailability = true) => css`
  background-color: ${hasAvailability
    ? theme.color.white
    : theme.color.backgroundGray};
  border-top: 1px solid
    ${hasAvailability ? theme.color.border : theme.color.backgroundGray};
  display: flex;
  justify-content: space-between;
  padding: ${theme.space.lg};
  margin-top: ${theme.space.base};
  width: 100%;
`;

const costLinkCss = css`
  color: ${theme.color.black};
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`;

const nextAvailableCss = (hasAvailability = true) => css`
  font-size: ${theme.fontSize.xs};
  color: ${hasAvailability ? theme.color.primaryMedium : theme.color.textGray};
`;

const modalContainerCss = css`
  align-items: center;
  display: flex;
  margin-top: ${theme.space.base};
  justify-content: center;
`;
const backIconCss = css`
  margin-right: ${theme.space.xs3};
  margin-left: ${theme.space.base};
  padding-left: ${theme.space.base};
  position: absolute;
  left: 0;
  width: 30px;
  height: 30px;
  svg {
    font-size: 20px;
  }
`;

export const BookingBanner = withStores(BookingBannerImpl);
