import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { ExpandableText } from './ExpandableText';

interface Props {
  items: string[];
  highlights?: string[];
}

const listFormatter = new Intl.ListFormat(undefined, { style: 'long' });

const ListHighlightable = ({ items, highlights = [] }: Props) => {
  const sorted = sortBy(items, (item) => item.toLowerCase());
  const [matches, others] = partition(sorted, (i) => {
    const isMatch = highlights.includes(i);
    return isMatch;
  });

  return (
    <ExpandableText buttonLabel="Show more" maxLines={2}>
      {listFormatter
        .formatToParts([...matches, ...others])
        .map(({ type, value }) => {
          if (type === 'literal') {
            return value;
          }

          const isHighlighted = highlights.includes(value);
          if (isHighlighted) {
            return <b className="text-system-black">{value}</b>;
          }

          return value;
        })}
    </ExpandableText>
  );
};

export { ListHighlightable };
