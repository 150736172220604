import { css } from '@emotion/react';
import React from 'react';

import { StyleTag } from '@headway/api/models/StyleTag';
import { getStyleTagProperties } from '@headway/shared/utils/providerStyleTag';
import { theme } from '@headway/ui/theme';

import { StyledSectionHeader } from './Text';

const tagNameCss = css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.sm};
  line-height: ${theme.fontSize.xl2};
  padding-left: ${theme.space.xs};
`;

const tagsContainerCss = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${theme.space.xs};
  row-gap: ${theme.space.sm};
`;

const tagCss = css`
  background-color: ${theme.color.primaryBackground};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  white-space: nowrap;
`;

type Props = {
  className?: string;
  firstName: string;
  sectionId: string;
  styleTags: StyleTag[];
};

const StyleSection: React.FC<Props> = ({
  className,
  firstName,
  sectionId,
  styleTags,
}) => {
  const tagsWithProperties = styleTags.map(getStyleTagProperties);

  return (
    <section id={sectionId} className={className}>
      <StyledSectionHeader>{firstName}'s style is</StyledSectionHeader>
      <div css={tagsContainerCss}>
        {tagsWithProperties.map((tag) => (
          <div css={tagCss} key={tag.name}>
            {tag.IconComponent ? <tag.IconComponent /> : null}
            <span css={tagNameCss}>{tag.name}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export { StyleSection };
