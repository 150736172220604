import { GenderStatus } from '@headway/api/models/GenderStatus';
import { ProviderGender } from '@headway/api/models/ProviderGender';
import { checkExhaustive } from '@headway/shared/utils/types';

export const shouldIncludeProviderGender = (
  providerGenderValue: string,
  providerCurrentGender?: ProviderGender | undefined
) => {
  if (providerCurrentGender === ProviderGender.TRANSGENDER_OR_NON_BINARY) {
    // If provider has TRANSGENDER_OR_NON_BINARY already selected,
    // show all 4 options (we don't want to forcibly remove this answer from providers)
    return true;
  } else {
    // If provider hasn't selected TRANSGENDER_OR_NON_BINARY,
    // hide that option and show NON_BINARY instead
    return providerGenderValue !== ProviderGender.TRANSGENDER_OR_NON_BINARY;
  }
};

export const GENDERS_TO_DISPLAY_NAMES: Record<ProviderGender, string> = {
  [ProviderGender.FEMALE]: 'Female',
  [ProviderGender.MALE]: 'Male',
  [ProviderGender.NON_BINARY]: 'Non-binary',
  [ProviderGender.TRANSGENDER_OR_NON_BINARY]: 'Transgender or Non-binary',
};

export const GENDER_STATUS_TO_DISPLAY_NAMES: Record<GenderStatus, string> = {
  [GenderStatus.CISGENDER]: 'Cisgender',
  [GenderStatus.TRANSGENDER]: 'Transgender',
  [GenderStatus.NOT_DISCLOSED]: 'Prefer not to answer',
};

export const providerFilterGenderOptions = [
  ...Object.entries(GENDERS_TO_DISPLAY_NAMES).map(([gender, displayName]) => {
    return {
      key: gender,
      text: displayName || '',
    };
  }),
  {
    key: GenderStatus.TRANSGENDER,
    text: 'Transgender',
  },
];

export const formatProviderGenderAndGenderStatus = (
  gender: ProviderGender,
  genderStatus: GenderStatus | undefined
) => {
  if (genderStatus) {
    switch (genderStatus) {
      case GenderStatus.CISGENDER:
        return `Cisgender ${GENDERS_TO_DISPLAY_NAMES[gender]}`;
      case GenderStatus.TRANSGENDER:
        return `Transgender ${GENDERS_TO_DISPLAY_NAMES[gender]}`;
      case GenderStatus.NOT_DISCLOSED:
        return `${GENDERS_TO_DISPLAY_NAMES[gender]}`;
      default:
        checkExhaustive(genderStatus);
    }
  }
  return `${GENDERS_TO_DISPLAY_NAMES[gender]}`;
};

export const isProviderGender = (value: any): value is ProviderGender => {
  return Object.values(ProviderGender).includes(value);
};
