import { css } from '@emotion/react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { Link, scrollSpy } from 'react-scroll';

import { theme } from '@headway/ui/theme';

import { HEADER_HEIGHT_VARIABLE } from '../../../utils/cssVariables';

type Section = {
  name: string;
  id: string;
};

type Props = {
  stickyHeadersHeight: number;
  sections: Section[];
};

export const StickyTabBar = React.forwardRef<HTMLDivElement, Props>(
  ({ stickyHeadersHeight, sections }, ref) => {
    const [activeTabId, setActiveTabId] = useState<string | undefined>();
    const scrollOffset = stickyHeadersHeight * -1 - 16;

    useEffect(() => {
      if (activeTabId === undefined) {
        const initialSectionFromHash = getWindowHash();
        const tabId = initialSectionFromHash
          ? sectionIdToTabId(initialSectionFromHash)
          : undefined;

        setActiveTabId(tabId);
      }

      scrollSpy.update();
    }, []);

    return (
      <div css={tabsContainerStyles} ref={ref}>
        <Tabs
          value={activeTabId || sectionIdToTabId(sections[0].id)}
          css={tabsStyles}
          aria-label="Provider profile tabs"
        >
          {sections.map((section) => (
            <Tab
              css={tabStyles}
              href={`#${section.id}`} // this is required to get the tab to render as an `a` tag instead of a button
              id={sectionIdToTabId(section.id)}
              key={section.id}
              label={section.name}
              tabIndex={0}
              value={sectionIdToTabId(section.id)}
              LinkComponent={(props) => {
                return (
                  <Link
                    {...props}
                    activeClass="active"
                    duration={200}
                    hashSpy={true}
                    href={`#${section.id}`}
                    offset={scrollOffset}
                    onSetActive={(sectionId) => {
                      const activeTab = sectionIdToTabId(sectionId);
                      setActiveTabId(activeTab);
                    }}
                    saveHashHistory={false}
                    smooth={true}
                    spy={true}
                    to={section.id}
                  />
                );
              }}
            />
          ))}
        </Tabs>
      </div>
    );
  }
);

const sectionIdToTabId = (sectionId: string) => `tab-section-${sectionId}`;

const getWindowHash = (): string | undefined => {
  if (typeof window === 'undefined') return;

  return window.location.hash.substring(1) || undefined;
};

const tabStyles = css`
  ${theme.media.muiMediumDown} {
    min-width: 0;
  }

  color: ${theme.color.textGray};

  &.Mui-selected {
    color: ${theme.color.primaryDark};
  }
`;

const tabsStyles = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow: visible;

  ${theme.media.muiMediumDown} {
    .MuiTabs-flexContainer {
      justify-content: flex-start;
      gap: 8px;
    }
  }

  .MuiTabs-scroller {
    overflow: visible !important; // overriding an inline style from mui smh
  }
  .MuiTabs-indicator {
    bottom: -1px;
  }
`;

const tabsContainerStyles = css`
  background-color: white;
  opacity: 100%;
  position: sticky;
  top: var(${HEADER_HEIGHT_VARIABLE});
  width: 100%;
  z-index: 2; // goes under "Get Exact Price" button and provider google map otherwise
`;
