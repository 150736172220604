import React from 'react';

import { HEADER_HEIGHT_VARIABLE } from './cssVariables';

export const getHeaderHeight = (): number => {
  const defaultHeight = 70;
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return defaultHeight;
  }

  const headerHeightStr = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(HEADER_HEIGHT_VARIABLE);
  const parsed = parseInt(headerHeightStr, 10);
  return isNaN(parsed) ? defaultHeight : parsed;
};

/**
 * Adds the `preflight-opt-in` class to the `html` element to opt-in to Tailwind's
 * preflight global styles. See _preflight.scss.  Use this at the highest point in the app
 * where you know you can safely use Tailwind's global styles without conflicting with
 * our legacy global styles.
 */
export function useTailwindGlobals() {
  React.useEffect(() => {
    document.querySelector('html')?.classList.add('preflight-opt-in');

    return () => {
      document.querySelector('html')?.classList.remove('preflight-opt-in');
    };
  }, []);
}
